import React, { useEffect } from 'react';
import {useRecoilState} from "recoil";
import styled from 'styled-components';
import {Alert} from '../../state/atoms'

const Index = () => {
    const [alert, setAlert] = useRecoilState(Alert);

    useEffect(() => {
        if (alert.display === true)
        setTimeout(() => {
            setAlert({display: false, msg: '', isErr: false});
        }, 3000);
        // eslint-disable-next-line
    }, [alert.display]);

    return (
        <Wrapper style={{ display: alert.display ? 'block' : 'none', background: alert.isErr ? '#ec6d6b' : '#62b42f' }}>
            {alert.msg}
        </Wrapper>
    );
};

export default Index;

const Wrapper = styled.div`
  min-width: 150px;
  padding: 20px;
  border-radius: 6px;
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 99999;
`