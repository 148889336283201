import React, {useEffect, useState} from 'react';
import {useRecoilState} from "recoil";
import {IsAddVideoOpen, UpdateVideo} from "../../../state/atoms";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import styled from 'styled-components';
import {api_url} from "../../../config";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '640px',
    bgcolor: '#474747',
    borderRadius: '8px',
    boxShadow: 24,
};

const Index = () => {
    const [isOpen, setOpen] = useRecoilState(IsAddVideoOpen);
    const [title, setTitle] = useState({value: '', err: ''});
    const [titleEng, setTitleEng] = useState({value: '', err: ''});
    const [link, setLink] = useState({value: '', err: ''});
    const [load, setLoad] = useState(false);
    const [updateDoc, setUpdateDoc] = useRecoilState(UpdateVideo);

    const handleClose = () => {
        setOpen(false);
    }
    const handleInputChange = (e, setState) => setState({err: '', value: e.target.value});
    const isInputValid = () => {
        let isValid = true;
        if (title.value.trim().length === 0) {
            setTitle({...title, err: 'Необходимо заполнить поле'})
            isValid = false;
        }
        if (titleEng.value.trim().length === 0) {
            setTitleEng({...titleEng, err: 'Необходимо заполнить поле'})
            isValid = false;
        }
        if (link.value.trim().length === 0) {
            setLink({...link, err: 'Необходимо заполнить поле'})
            isValid = false;
        }
        return isValid;
    }
    const updateDocList = async () => {
        const fullObject = {
            link: link.value,
            title: title.value,
            titleEng: titleEng.value,
        }
        await fetch(`${api_url}/video`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem('tokenWiki')
            },
            body: JSON.stringify(fullObject)
        })
    }
    const handleSubmit = async () => {
        if (isInputValid()) {
            setLoad(true);
            await updateDocList();
            setUpdateDoc(!updateDoc);
            setTimeout(()=>{
                setLoad(false);
                handleClose();
            }, 1000)
        }
    }

    useEffect(() => {
        setTitle({value: '', err: ''});
        setTitleEng({value: '', err: ''});
        setLink({value: '', err: ''});
    }, [isOpen]);

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={isOpen}>
                <Box sx={style}>
                    <ModalHeader>
                        <ModalTitle>Создание видео</ModalTitle>
                    </ModalHeader>
                    <CloseContainer >
                        <CloseButton type="button" onClick={handleClose}>
                            <span>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" stroke="none" aria-hidden="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
                                            <path fill="currentColor" fillRule="evenodd" d="M3.47 3.47a.75.75 0 0 1 1.06 0L8 6.94l3.47-3.47a.75.75 0 1 1 1.06 1.06L9.06 8l3.47 3.47a.75.75 0 1 1-1.06 1.06L8 9.06l-3.47 3.47a.75.75 0 0 1-1.06-1.06L6.94 8 3.47 4.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd">
                                            </path>
                                        </svg>
                                    </svg>
                                </span>
                            </span>
                        </CloseButton>
                    </CloseContainer>
                    <ModalBody>
                        <InputContainer>
                            <div className="title">
                                Название
                            </div>
                            <InputWrapper>
                                <ModalTextInput className={title.err ? 'error': ''} type="text" value={title.value} onChange={(e)=>handleInputChange(e, setTitle)}/>
                                {title.err && <ErrorMsg>{title.err}</ErrorMsg>}
                            </InputWrapper>
                        </InputContainer>
                        <InputContainer>
                            <div className="title">
                                Название (Eng)
                            </div>
                            <InputWrapper>
                                <ModalTextInput className={titleEng.err ? 'error': ''} type="text" value={titleEng.value} onChange={(e)=>handleInputChange(e, setTitleEng)}/>
                                {titleEng.err && <ErrorMsg>{titleEng.err}</ErrorMsg>}
                            </InputWrapper>
                        </InputContainer>
                        <InputContainer>
                            <div className="title">
                                Ссылка
                            </div>
                            <InputWrapper>
                                <ModalTextInput className={link.err ? 'error': ''} type="text" value={link.value} onChange={(e)=>handleInputChange(e, setLink)}/>
                                {link.err && <ErrorMsg>{link.err}</ErrorMsg>}
                            </InputWrapper>
                        </InputContainer>
                        <ModalFooter>
                            <FooterButton type='button' className={load? 'load' : ''} onClick={handleClose} disabled={load}>
                                Отмена
                            </FooterButton>
                            <FooterButton type='button' className={load? 'load' : 'submit'} onClick={handleSubmit} disabled={load} >
                                Создать
                            </FooterButton>
                        </ModalFooter>
                    </ModalBody>
                </Box>
            </Fade>
        </Modal>
    );
};

export default Index;

const ModalTitle = styled.div`
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
`
const ModalHeader = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  line-height: 24px;
  padding: 20px calc( 32px + 28px) 10px 32px;
`
const CloseContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 14px;
  z-index: 1;
`
const CloseButton = styled.button`
    width: 28px;
    height: 28px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #fff;
    background: transparent;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    transition: all .15s ease-out;
    &:hover {
      background: rgba(255, 255, 255, 0.25);
    }
`
const InputContainer = styled.div`
    display: flex;
    margin-bottom: 20px;
     .title {
      flex-shrink: 0;
      margin-top: 5px;
      width: 120px;
    }
`
const ModalBody = styled.div`
    padding: 10px 32px;
  font-size: 13px;
`
const ModalTextInput = styled.input`
  display: inline-block;
  width: 100%;
  background: transparent;
  color: #fff;
  border: 1px solid hsla(0,0%,100%,.15);
  border-radius: 6px;
  height: 26px;
  padding: 5px 8px;
  outline: none;
  &.error {
    border: 1px solid #ec6d6b;
  }
  &:focus {
    outline: 1px solid #fff;
  }
  
`

const ModalFooter = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  padding-bottom: 16px;
`
const FooterButton = styled.button`
  height: 36px;
  line-height: 36px;
  font-weight: 400;
  font-size: 13px;
  white-space: nowrap;
  border-radius: 8px;
  padding: 0 16px;
  border: none;
  cursor: pointer;
  transition: all .15s ease-out;
  color: #fff;
  background: hsla(0, 0%, 100%, .1);

  &.submit {
    background: hsl(0deg 0% 53.94%);
  }
  &.load {
    background: linear-gradient(120deg, rgba(73, 73, 73, 0.94), rgba(131, 131, 131, 0.75), rgba(73, 73, 73, 0.94));
    background-size: 300% 100%;
    animation: gradientMove 1s ease infinite;
    pointer-events: none;
  }
  @keyframes gradientMove {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 50%;
    }
  }

  &:hover {
    background: hsla(0, 0%, 100%, .25);
  }
`
const ErrorMsg = styled.div`
  color: #ec6d6b;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 2px;
  white-space: normal;
`
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`