import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {IsAdmin} from "../state/atoms";
import {api_url} from "../config";

const Login = () => {
    const navigate = useNavigate();
    const setAdmin = useSetRecoilState(IsAdmin);
    const getLogin = async () => {
        let x = false;
        while (!x) {
            try {
            let password = prompt('Password:');
            const queryParams = new URLSearchParams();
            queryParams.append('password', password);
            const url = `${api_url}/auth?${queryParams.toString()}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                }
            });
            const token = await response.json();
            x = response.status === 200;
            localStorage.setItem('tokenWiki', token.token);
            } catch (e) {
                console.log(e);
            }
        }
        setAdmin(true);
        navigate('/');
    }

    useEffect(() => {
        getLogin();
        // eslint-disable-next-line
    }, []);

    return (
        <>
        </>
    );
};

export default Login;