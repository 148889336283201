import {atom} from "recoil";

export const SelectedPage = atom({
    key: 'selectedPage',
    default: localStorage.getItem('selected_page'),
});
export const IsAdmin = atom({
    key: 'isUserAdmin',
    default: false,
});
export const IsCreateOpen = atom({
    key: 'isOpen',
    default: {
        value: false,
        link:'',
    },
});
export const MenuItems = atom({
    key: 'Menu',
    default: [],
});
export const blockScroll = atom({
    key: 'scroll',
    default: false,
});
export const UpdateSidebarFlag = atom({
    key: 'flag',
    default: false,
});
export const IsAddDocOpen = atom({
    key: 'addDocsModal',
    default: false,
});
export const IsAddVideoOpen = atom({
    key: 'addvideo',
    default: false,
});

export const UpdateDoc = atom({
    key: 'isUpdateDoc',
    default: false,
});
export const SupportModal = atom({
    key: 'isSupModal',
    default: false,
});
export const Alert = atom({
    key: 'isalert',
    default: {display: false, msg: '', isErr: false},
});
export const UpdateVideo = atom({
    key: 'isUpdateVideos',
    default: false,
});
export const Lang = atom({
    key: 'dictionary',
    default: localStorage.getItem('wiki_lang') || 'ru',
});
