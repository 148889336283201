import React from 'react';
import styled from 'styled-components'
import {useRecoilValue, useSetRecoilState} from "recoil";
import {IsAdmin, IsCreateOpen} from "../../../state/atoms";

const Index = ({ link }) => {
    const isAdmin = useRecoilValue(IsAdmin);
    const setCreateOpen = useSetRecoilState(IsCreateOpen);
    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setCreateOpen({value: true, link: link});
    }
    return (
        <>
        {isAdmin &&
            <AddPageButton type="button" onClick={(e) => handleClick(e)}>
                        <span>
                            <span style={{display: 'flex'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" className="yc-icon" fill="currentColor" stroke="none" aria-hidden="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
                                        <path fill="currentColor" fillRule="evenodd" d="M8 1.75a.75.75 0 0 1 .75.75v4.75h4.75a.75.75 0 0 1 0 1.5H8.75v4.75a.75.75 0 0 1-1.5 0V8.75H2.5a.75.75 0 0 1 0-1.5h4.75V2.5A.75.75 0 0 1 8 1.75Z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </svg>
                            </span>
                        </span>
            </AddPageButton>
        }
        </>
    );
};

export default Index;

const AddPageButton = styled.button`
    width: 28px;
    height: 28px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #fff;
    background: transparent;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    transition: all .15s ease-out;
    &:hover {
      background: rgba(255, 255, 255, 0.25);
    }
`