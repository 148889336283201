import Routing from "./routing";
import GlobalStyle from "./globalStyles";
import styled from 'styled-components';
import { useSetRecoilState} from "recoil";
import React, {useEffect} from "react";
import {IsAdmin} from "./state/atoms";
import {api_url} from "./config";

function App() {
    const setAdmin = useSetRecoilState(IsAdmin);
    useEffect(() => {
        async function isAuth() {
            try {
                const response = await fetch(`${api_url}/auth/token`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem('tokenWiki')
                    }
                });
                setAdmin(response.status === 200);
            } catch (e) {
                console.log(e);
            }
        }
        isAuth();
        // eslint-disable-next-line
    }, []);
  return (

    <Container>
        <GlobalStyle/>
        <Routing/>
    </Container>

  );
}

export default App;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`
