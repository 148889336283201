import React, { useEffect, useState } from 'react';
import Sidebar from "../components/Sidebar";
import { useLocation, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import MardownPage from "../components/MarkdownGenerator";
import Page from "../skeletons/Page";
import {URLparser} from "../utils/kb-functions";
import {Lang, SelectedPage} from '../state/atoms'
import {useSetRecoilState, useRecoilValue} from "recoil";
import AddPageModal from '../components/Modals/CreatePage'
import {Helmet} from "react-helmet";
import {dictionary} from "../constants/dictionary";
import {api_url} from "../config";

const KnowledgeBase = () => {
    const [page, setPage] = useState(null);
    const [loading, setLoading] = useState(true);
    const setSelectedPage = useSetRecoilState(SelectedPage);
    const navigate = useNavigate();
    const location = useLocation();
    const lang = useRecoilValue(Lang);
    const langDictionary = dictionary[lang] || {};

    let pageName = '';
    const getPage = async (pageName) => {
        const queryParams = new URLSearchParams();
        queryParams.append('link', pageName);
        const url = `${api_url}/page?${queryParams.toString()}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                }
            });
            return await response.json();
        } catch (e) {
            console.log(e);
        }
    }
    const pageRouter = async (pageName) => {
        switch (pageName) {
            case '/':
                navigate('user-guide');
                break;
            case '/404':
                setPage({link: '/404', title: 'Страница не найдена',titleEng: 'Page not found', content: '',contentEng: ''});
                break;
            default:
                const findedPage = await getPage(pageName);
                if (findedPage) {
                    setPage(findedPage);
                    setSelectedPage(pageName);
                    localStorage.setItem('selected_page', pageName);
                } else {
                    navigate('404');
                    setSelectedPage('');
                    localStorage.setItem('selected_page', '');
                }
                break;
        }
        setTimeout(()=>{
            setLoading(false);
        }, 500)
    }

    useEffect(() => {
        setLoading(true);
        // eslint-disable-next-line
        pageName = URLparser(location);
        pageRouter(pageName);
    }, [location]);

    return (
        <ResponsiveContainer>
            <Helmet>
                <title>{langDictionary.homePageItem1Title}</title>
                <meta name="description" content={langDictionary.homePageItem1Title} />
            </Helmet>
            <Sidebar/>
            <AddPageModal/>
            <PageContainer>
                {loading || !page ?
                    <Page/>
                    :
                    <MardownPage title={lang === 'ru' ? page.title : page.titleEng} content={lang === 'ru' ? page.content : page.contentEng}/>
                }
            </PageContainer>
        </ResponsiveContainer>
    );
};

export default KnowledgeBase;
const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 768px){
    flex-direction: column;
  }
`
const PageContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  padding: 0 40px 32px;
  @media screen and (max-width: 768px){
    margin-top: 60px;
    padding: 0 15px;
  }
`