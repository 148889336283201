import React from 'react';
import {Skeleton} from "@mui/material";

const Menu = () => {
    return (
        <div style={{ padding: '0 10px'}}>
            <Skeleton variant="text" style={{ height: '36px' , background: '#676666'}} />
            <Skeleton variant="text" style={{ height: '36px' , background: '#676666'}} />
            <Skeleton variant="text" style={{ height: '36px' , marginRight: '16px', background: '#676666'}} />
            <Skeleton variant="text" style={{ height: '36px' , marginRight: '16px', background: '#676666'}} />
            <Skeleton variant="text" style={{ height: '36px', background: '#676666' }} />
            <Skeleton variant="text" style={{ height: '36px' , marginRight: '16px', background: '#676666'}} />
        </div>
    );
};

export default Menu;