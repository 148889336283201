import React, {useState} from 'react';
import {Lang} from "../../state/atoms";
import {useRecoilState} from 'recoil';
import styled from 'styled-components';
import {dictionary} from "../../constants/dictionary";

const Index = () => {
    const [lang, setLang] = useRecoilState(Lang);
    const [checked, setChecked] = useState(lang !== 'ru');
    const langDictionary = dictionary[lang] || {};
    const handleChange = (event) => {
        setChecked(event.target.checked);
        if (checked) {
            setLang('ru');
            localStorage.setItem('wiki_lang', 'ru')
        } else {
            setLang('en');
            localStorage.setItem('wiki_lang', 'en')
        }
    };
    return (
        <Wrapper>
            <Labels>
                <p>{langDictionary.langRu}</p>
                <SwitchInputChecked type="checkbox" id="switch" checked={checked} onChange={handleChange} />
                <SwitchLabel htmlFor="switch">
                    <SwitchLabelAfter />
                </SwitchLabel>
                <p>{langDictionary.langEn}</p>
            </Labels>
        </Wrapper>
    );
};

export default Index;
const Wrapper = styled.div`
  position: absolute;
  right: 25px;
  top: 25px;
  width: 50px; 
  display: flex;
  align-items: center;
  @media screen and (max-width: 880px){
    position: relative;
    width: 100%;
    right: 0;
    justify-content: center;
  }
`;
const Labels = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 13px;
  color: gray;
  p {
    margin: 0;
  }
  @media screen and (max-width: 880px){
    flex-direction: row;
    align-items: center;
  }
`
const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

const SwitchLabel = styled.label`
  cursor: pointer;
  text-indent: -9999px;
  width: 30px; 
  height: 50px; 
  background: grey;
  display: block;
  border-radius: 50px; 
  position: relative;
  @media screen and (max-width: 880px){
    width: 50px;
    height: 30px;
  }
`;

const SwitchLabelAfter = styled.div`
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
  background: #4fb612;
  border-radius: 90px;
  transition: all 0.3s ease-out;
`;

const SwitchInputChecked = styled(SwitchInput)`
  &:checked + ${SwitchLabel} ${SwitchLabelAfter} {
    top: calc(100% - 25px);
    @media screen and (max-width: 880px){
      left: calc(100% - 25px);
    }
  }
`;

