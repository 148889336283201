import React from 'react';
import styled from 'styled-components'
import {useRecoilState, useRecoilValue} from "recoil";
import {IsAdmin, UpdateSidebarFlag} from "../../../state/atoms";
import {useNavigate} from "react-router-dom";
import {api_url} from "../../../config";

const Index = ({ link }) => {
    const isAdmin = useRecoilValue(IsAdmin);
    const navigate = useNavigate();
    const [updateFlag,needUpdateSidebar] = useRecoilState(UpdateSidebarFlag);
    const deletePageAndLink = async (link) => {
        const queryParams = new URLSearchParams();
        queryParams.append('link', link);
        const urlLikn = `${api_url}/link?${queryParams.toString()}`;
        await fetch(urlLikn, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem('tokenWiki')
            },
        })
        const urlPage = `${api_url}/page?${queryParams.toString()}`;
        await fetch(urlPage, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem('tokenWiki')
            },
        })

        needUpdateSidebar(!updateFlag);
        navigate('/knowledge-base');
    }
    const handleClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (window.confirm("Вы уверены, что хотите продолжить?")) {
            await deletePageAndLink(link);
        }
    }
    return (
        <>
            {isAdmin &&
                <DeletePageButton type="button" onClick={(e) => handleClick(e)}>
                        <span>
                            <span style={{display: 'flex'}}>
                              <svg width="14px" height="14px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                  <path fill="#fff" d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32zm192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32z"/>
                              </svg>
                            </span>
                        </span>
                </DeletePageButton>
            }
        </>
    );
};

export default Index;

const DeletePageButton = styled.button`
    width: 28px;
    height: 28px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #fff;
    background: transparent;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    transition: all .15s ease-out;
    &:hover {
      background: rgba(255, 255, 255, 0.25);
    }
`