import React from 'react';

const NotFound = () => {
    return (
        <div style={{margin:'200 auto'}}>
            Page not found 404
        </div>
    );
};

export default NotFound;