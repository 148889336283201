import React from 'react';
import {Skeleton} from "@mui/material";
import styled from 'styled-components';
const Page = () => {
    return (
        <Container>
            <Skeleton variant="text" style={{ height: '90px' , background: '#494848'}} />
            <Skeleton variant="text" style={{ height: '36px' , background: '#494848'}} />
            <Skeleton variant="text" style={{ height: '36px', marginRight: '10%', background: '#494848'}} />
            <Skeleton variant="text" style={{ height: '36px', marginRight: '7%', background: '#494848'}} />
            <Skeleton variant="text" style={{ height: '36px', marginRight: '18%', background: '#494848'}} />
            <Skeleton variant="text" style={{ height: '36px' , background: '#494848'}} />
            <Skeleton variant="text" style={{ height: '36px', marginRight: '3%', background: '#494848'}} />
            <Skeleton variant="text" style={{ height: '36px' , background: '#494848'}} />
            <Skeleton variant="text" style={{ height: '36px', marginRight: '12%', background: '#494848'}} />
            <Skeleton variant="text" style={{ height: '36px' , background: '#494848'}} />
            <Skeleton variant="text" style={{ height: '72px' , background: '#494848'}} />
            <Skeleton variant="text" style={{ height: '36px' , background: '#494848'}} />
            <Skeleton variant="text" style={{ height: '36px', marginRight: '20%', background: '#494848'}} />
            <Skeleton variant="text" style={{ height: '36px' , background: '#494848'}} />
            <Skeleton variant="text" style={{ height: '36px', marginRight: '40%', background: '#494848'}} />
        </Container>
    );
};

export default Page;
const Container = styled.div`
    max-height: 80vh;
    overflow: hidden;
`
