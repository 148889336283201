import React, {useEffect} from 'react';
import {Route, Routes} from "react-router-dom";
import Home from "../pages/Home";
import KnowledgeBase from "../pages/KnowledgeBase";
import {useRecoilValue} from "recoil";
import {blockScroll} from "../state/atoms";
import Docs from "../pages/Docs";
import Video from "../pages/Video";
import NotFound from "../pages/NotFound";
import Login from "../pages/Login";
import {IsAdmin} from '../state/atoms'

const Index = () => {
    const isScrollBlocked = useRecoilValue(blockScroll);
    const isAdmin = useRecoilValue(IsAdmin);

    useEffect(() => {
        isScrollBlocked ? document.body.style.overflow = 'hidden' : document.body.style.overflow = '';
    }, [isScrollBlocked]);
    return (
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/knowledge-base/*" element={<KnowledgeBase />} />
            <Route path="/docs" element={<Docs />} />
            <Route path="/login" element={<Login />} />
            <Route path="/video-tutorials" element={<Video />} />
            <Route path="*" element={<NotFound/>} />
        </Routes>
    );
};

export default Index;